import React from 'react';
import './Home.scss';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className='home'>
      <div className="left">
        <h1 className='title'>I'm Neeraj</h1>
        <p className='desc'>
          Hello there! I am a software developer based in India with a passion for crafting elegant solutions. 
          With a strong foundation in various programming languages and a knack for problem-solving, 
          I strive to create efficient and user-friendly applications that make a positive impact. 
          Let's collaborate and bring your ideas to life!
        </p>
        <Link to='https://mega.nz/file/lztASDiT#0LNkyeUS8ibXbuHlV8MxISyCylZ08VJvq4hfrO4ggR4' className='link'>
          <button className='cv'>Download CV</button>
        </Link>
      </div>
      <div className="right">
        <img src='/img/me.jpg' alt='' />
      </div>
    </div>
  )
}

export default Home