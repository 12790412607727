import React from 'react';
import './Projects.scss';
import Card from '../Card/Card';

const Projects = () => {

  const data = [
    {
      title: 'Entice',
      description: 'Developed "Entice," an exquisite skincare ecommerce website, using React.js and powerful libraries like Swiper, Auto Animate, and Framer Motion, to deliver a visually captivating and seamless shopping experience for skincare enthusiasts',
      imageSrc: '/img/entice.png',
      link: 'https://entice.pages.dev/',
      srcLink: 'https://github.com/JerryXO/Entice'
    },
    {
      title: 'Fandom',
      description: 'Created Fandom, a visually stunning NFT marketplace, utilizing HTML, CSS, and Bootstrap, to provide art enthusiasts with a captivating platform to discover and collect exclusive digital artworks.',
      imageSrc: '/img/fandom.png',
      link: 'https://jerryxo.github.io/Fandom-NFT-Marketplace/',
      srcLink: 'https://github.com/JerryXO/Fandom-NFT-Marketplace'
    }
  ]
  return (
    <div className="projects">
      {data.map(item =>(
        <Card item={item} key={item.id}/>
      ))}
    </div>
  )
}

export default Projects